import React, { useContext } from "react";
import SiteDataContext from "../context/SiteDataContext";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout/layout";
import RichText from "../components/organisms/RichText/richText";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import { Title } from "../components/atoms/Title/title";
import { Helmet } from "react-helmet";

const InstructionsTemplate = ({ data }) => {
  const { contentfulInstructionsPost: page } = data;
  const siteData = useContext(SiteDataContext);

  return (
    <Layout
      headerFull={true}
      hideMenuButton={false}
      backgroundColor={"instructions"}>
      <Helmet>
        <title>{page.title}</title>
        <meta
          name="description"
          content={page.metaDescription.metaDescription}
        />
      </Helmet>
      <div>
        <Wrapper paddingTop>
          <Title
            grand
            content={page.title}
          />
          <RichText
            customClass={`richText--${page.contentCustomClass}`}
            content={page.content}
            siteData={siteData}
          />
        </Wrapper>
      </div>
    </Layout>
  );
};

export default InstructionsTemplate;

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulInstructionsPost(slug: { eq: $slug }) {
      title
      metaDescription {
        metaDescription
      }
      content {
        raw
        references {
          contentful_id
          __typename
          id
          title
          content {
            raw
          }
          childContentfulTablesTableTextNode {
            table
          }
        }
      }
      contentCustomClass
    }
  }
`;
